.listStyle ul {
  @apply list-disc ml-4 my-6 list-outside;
}

.paragraphStyle p:not(:first-child) {
  margin-top: 1em;
}

.paragraphStyle p:empty {
  margin-top: 0em;
}
