.menuList ul li:before {
  content: '\2713';
  margin-right: 10px;
}

.mobileWidth1024 {
  width: 1024px;
}

@screen md {
  .mobileWidth1024 {
    width: 100%;
  }
}

/* styles all tabs */
.cpTabbedStyles [data-reach-tab-list] {
  background: rgba(0, 0, 0, 0);
}

.cpTabbedStyles [data-reach-tab] {
  @apply border-b-4 text-xl py-3 px-6 sm:py-6 sm:px-8 sm:-mb-1;
}
/* styles only the selected tab */
.cpTabbedStyles [data-reach-tab][data-selected] {
  @apply border-b-4 border-primary;
}

.tabAccent {
  top: 53px;
}

@sm {
  .tabAccent {
    top: 76px;
  }
}
