@import 'tailwindcss/utilities';

/* not getting generated somehow */
.stroke-text {
  stroke: rgb(var(--color-text));
}

/**
* fixes ios safari bug
**/
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
  .min-h-screen {
    min-height: -webkit-fill-available;
  }
}

/**
* fixes grid columns
**/
@media (min-width: 1024px) {
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }
}
