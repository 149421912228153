.label {
  @apply text-base font-bold inline-flex justify-start flex-col;
}
.label > :not(template) + :not(template) {
  @apply mt-1;
}
.label-reverse {
  @apply flex-col-reverse justify-end;
}
.label-reverse > :not(template) + :not(template) {
  @apply mt-0 mb-1;
}

.label-inline {
  @apply flex-row items-center;
}
.label-inline > :not(template) + :not(template) {
  @apply ml-2 mt-0;
}

.label-inline-reverse {
  @apply flex-row-reverse items-center justify-end;
}
.label-inline-reverse > :not(template) + :not(template) {
  @apply mr-2 mt-0;
}

.label > span {
  @apply whitespace-nowrap;
}

.label-error {
  @apply text-danger;
}
