.btn {
  @apply inline-block appearance-none border border-solid rounded font-bold whitespace-normal lg:whitespace-nowrap leading-normal;
}
.btn:hover {
  @apply duration-100;
}
.btn:focus {
  @apply outline-none duration-100;
}
.btn:disabled {
  @apply cursor-not-allowed;
}
