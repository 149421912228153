:root {
  --reach-dialog: 1;
  --reach-menu-button: 1;
}

::selection {
  @apply bg-primary text-text-inverse;
}
html {
  @apply antialiased bg-bg text-text font-sans;
  line-height: normal;
  text-rendering: optimizeLegibility;
}
