@import 'tailwindcss/components';

@import 'components/transitions.css';
@import 'components/btn.css';
@import 'components/container.css';
@import 'components/dialog.css';
@import 'components/label.css';
@import 'components/location-google-map.css';

*:focus:not(.exclude-focus) {
  @apply outline-none ring-2 ring-offset-1 ring-primary duration-100 !important;
}
