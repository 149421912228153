.dialog-overlay {
  @apply bg-overlay fixed inset-0 overflow-auto z-10 leading-none backdrop-blur;
}
.dialog-content-v2 {
  @apply bg-bg relative p-4 outline-none outline-2 outline-offset-2 mx-auto w-full overflow-y-auto;
}

@screen sm {
  .dialog-content-v2 {
    @apply w-3/4 rounded-lg;
  }
}
@screen xl {
  .dialog-content-v2 {
    @apply w-2/3;
  }
}
@screen 2xl {
  .dialog-content-v2 {
    @apply w-1/2;
  }
}
