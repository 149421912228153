.verticalText {
  writing-mode: tb;
  transform: rotate(180deg);
}

/* Scroll Bar Styles */

/* Firefox */
.flyoutContent {
  scrollbar-width: thin;
  scrollbar-color: rgb(var(--color-primary)) rgba(var(--color-bg), 0.25);
}

/* Chrome, Edge, and Safari */
div.flyoutContent::-webkit-scrollbar {
  width: 10px;
}

div.flyoutContent::-webkit-scrollbar-track {
  background: rgba(var(--color-bg), 0.25);
  border-radius: 5px;
}

div.flyoutContent::-webkit-scrollbar-thumb {
  background-color: rgb(var(--color-primary));
  border-radius: 14px;
  border: 3px solid rgb(var(--color-primary));
}
